import { memoize } from 'lodash-es'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import Duration from 'dayjs/plugin/duration'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import Calendar from 'dayjs/plugin/calendar'
import Utc from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(Duration)
dayjs.extend(AdvancedFormat)
dayjs.extend(Calendar)
dayjs.extend(Utc)
dayjs.extend(Timezone)
dayjs.extend(quarterOfYear)

import {
    DATE_HUMAN_FORMAT,
    DATE_LONG_FORMAT,
    DATE_LONG_HUMAN_FORMAT,
    DATE_LONG_TIME_HUMAN_FORMAT,
    DATE_SERVER_FORMAT,
    DATE_SERVER_LONG_FORMAT,
    DATE_SHORT_FORMAT,
    DATE_TIME_HUMAN_FORMAT,
} from '@tenant/utils/constants'
import { useI18n } from 'vue-i18n'

export const now = memoize(() => dayjs())

export const getDate = (
    date,
    format = {},
    strict = false,
    isReplaceTimezone = true
) => dayjs(isReplaceTimezone ? replaceTimezone(date) : date, format, strict)

export const dayjsFormat = memoize(
    (date, format) => (date ? getDate(date).format(format) : null),
    (...args) => JSON.stringify(args)
)

export const dayjsFormatWithTimezone = memoize(
    (date, format) =>
        date ? getDate(date, {}, false, false).format(format) : null,
    (...args) => JSON.stringify(args)
)

export const shortFormat = memoize((date) =>
    date ? getDate(date).format(DATE_SHORT_FORMAT) : null
)

export const humanFormat = memoize((date) =>
    date ? getDate(date).format(DATE_HUMAN_FORMAT) : null
)

export const humanLongFormat = memoize((date) =>
    date ? getDate(date).format(DATE_LONG_HUMAN_FORMAT) : null
)

export const customDateFormat = memoize(
    (date, format, fromFormat = {}, strict = false) =>
        date ? getDate(date, fromFormat, strict).format(format) : null
)

export const humanTimeFormat = memoize((date) =>
    date ? getDate(date).format(DATE_TIME_HUMAN_FORMAT) : null
)

export const humanLongTimeFormat = memoize((date) =>
    date ? getDate(date).format(DATE_LONG_TIME_HUMAN_FORMAT) : null
)

export const shortServerFormat = memoize((date) =>
    date ? getDate(date).format(DATE_SERVER_FORMAT) : null
)

export const longFormat = memoize((date) =>
    date ? getDate(date).format(DATE_LONG_FORMAT) : null
)

export const longServerFormat = memoize((date) =>
    date ? getDate(date).format(DATE_SERVER_LONG_FORMAT) : null
)

export const nativeDateFormat = memoize((date) => {
    if (!date) {
        return null
    }

    return getDate(date, [
        DATE_SERVER_FORMAT,
        DATE_SERVER_LONG_FORMAT,
        DATE_LONG_FORMAT,
        DATE_SHORT_FORMAT,
    ]).toDate()
})

export const dayToISOString = memoize(
    (date, format = DATE_LONG_FORMAT) =>
        date ? getDate(date, format).format('YYYY-MM-DD HH:mm:ss') : null,
    (...args) => JSON.stringify(args)
)

export const validDate = memoize((date, fromFormat = {}, strict = false) =>
    getDate(date, fromFormat, strict).isValid()
)

export const fromNow = memoize((date) => getDate(date).fromNow())

export const dayDiff = memoize(
    (date1, date2, unit = 'd') => getDate(date1).diff(getDate(date2), unit),
    (...args) => JSON.stringify(args)
)

export const diffDayFromNow = memoize(
    (date, unit = 'd') => {
        const { t } = useI18n()
        const diff = dayDiff(date, now(), unit)
        return diff === 0
            ? t('general.today')
            : dayjs.duration(diff, unit).humanize(true)
    },
    (...args) => JSON.stringify(args)
)

export const diffFromNow = memoize(
    (date, unit = 'd') => {
        const diff = dayDiff(date, now(), unit)

        return dayjs.duration(diff, unit).humanize(true)
    },
    (...args) => JSON.stringify(args)
)

export const calendar = memoize((date) =>
    dayjs().calendar(getDate(date), {
        sameDay: '[Today]',
    })
)

const replaceTimezone = (date) => {
    if (typeof date === 'string') {
        return date.replace('Z', '')
    }

    return date
}

export const humanizedDuration = (diff, unit) => {
    return dayjs.duration(diff, unit).humanize(true)
}
